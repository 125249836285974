import { terriblerBrokenerBadder as attribution } from 'rng/attributions';
import { Attribution, TableEntry } from 'types/character';

const entry = (attr: Attribution, tag: string, titleId: string, id: string): TableEntry => ({
  id: `${attr.id}-${id}`,
  tags: [attr.authors[0].id, attr.id, tag, id],
  attribution: attr,
  content: {
    tags: [attr.authors[0].id, attr.id, tag, id],
    title: {
      id: `character.stats.standard.${titleId}`,
      values: {},
    },
    description: {
      id: `content.${attr.authors[0].id}.${attr.id}.${id}`,
      values: {},
    },
  },
});

export const terriblerTraits = [
  'lustful',
  'gluttonous',
  'greedy',
  'slothful',
  'wrathful',
  'envious',
  'prideful',
  'afraidOfPain',
  'compulsiveLiar',
  'covetous',
  'rebellious',
  'aloof',
  'stupid',
  'secretive',
  'grumpy',
  'rude',
  'fearful',
  'indecisive',
  'stubborn',
  'resentful',
  'unfocused',
  'vain',
  'mean',
  'argumentative',
  'unreliable',
  'naive',
  'selfish',
  'dull',
  'nervous',
  'reckless',
  'impulsive',
  'weakWilled',
  'fatalistic',
  'unimaginative',
  'hopeless',
  'untrustworthy',
].map(x => entry(attribution, 'trait', 'traits', x));

export const brokenerBodies = [
  'falseEye',
  'noseLost',
  'arthritic',
  'speckledSkin',
  'coveredInScars',
  // scvmfurther2 already has a peg leg
  // 'pegLeg',
  'incontinent',
  'myopic',
  'halitosis',
  'earChewedOff',
  'clubFoot',
  'handFrozen',
  'hunchback',
  'hearingLoss',
  'droolProblem',
  'irremovableSplinter',
  'bitOffTongue',
  'hatchetFight',
  'soreBack',
  'loudSnorer',
  'shakyHands',
  'witheredArm',
  'badKnees',
  'leprosy',
  'chronicCough',
  'proneToFits',
  'narcoleptic',
  'splitBlood',
  'brokenJaw',
  'oddGait',
  'bodyReek',
  'longNose',
  'doubleJointed',
  'armPopsFromSocket',
  'sweatLikePig',
  'spasms',
].map(x => entry(attribution, 'body', 'body', x));

export const badderHabits = [
  'addictedToSnuff',
  'peelOwnSkin',
  'keepTrophies',
  'kleptomaniac',
  'refuseToBathe',
  'gambleAtEverything',
  'mustWashHands',
  'talkTooQuietly',
  'notSoSecretCannibal',
  'scabCollector',
  'badAtGoodbyes',
  'repeatEverything',
  'soundEffects',
  'stressEater',
  'fingernailBiute',
  'overexplainer',
  'refuseToCompromise',
  'takeCredit',
  'disorganized',
  'fascinatedByFilth',
  // scvmfurther2 already has bully as a trait
  // 'bully',
  'dontListen',
  'singDuringCombat',
  'drunkAtBreakfast',
  'overrelyOnothers',
  // feels a bit too sexual for MB
  // 'preferToBeNude',
  'alwaysVictim',
  'poseTheDead',
  'cheater',
  // i feel this might be problematic in the same way as bipolar in MB's first printing
  // 'beatSelf',
  'easiestWay',
  'eatUntilSick',
  // feels a bit too sexual for MB
  // 'publicUrinator',
  'abhorSilence',
  'doubleCrosser',
  'dirtyFighter',
].map(x => entry(attribution, 'habit', 'habit', x));
