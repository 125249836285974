import { scvmatorium } from 'rng/attributions';
import { TableEntry } from 'types/character';

const entry = (id: string): TableEntry => ({
  id: `scvmatorium-${id}`,
  tags: ['makedatanotlore', 'scvmfurther2', 'trait', id],
  attribution: scvmatorium,
  content: {
    tags: ['makedatanotlore', 'scvmfurther2', 'trait', id],
    title: {
      id: 'character.stats.standard.traits',
      values: {},
    },
    description: {
      id: `content.makedatanotlore.scvmfurther2.${id}`,
      values: {},
    },
  },
});

export const traits = [
  'meanSpirited',
  'maverick',
  'gluttonous',
  'quickToAnger',
  'obsessive',
  'rash',
  'slothful',
  'protective',
  'liceridden',
  'theatrical',
  'controlling',
  'slimy',
  'hoarse',
  'groveling',
  'greasy',
  'squeeky',
  'sedentary',
  'mealymouthed',
  'miserly',
  'aching',
  'antiheroic',
  'wheezing',
  'venerating',
  'adversarial',
  'pissy',
  'rowdy',
  'loneWolf',
  'bristly',
  'annoying',
  'esoteric',
  'sly',
  'craven',
  'dripping',
  'hungry',
  'sticky',
  'nasty',
  'jumpy',
  'cold',
  'superstitious',
  'snarky',
  'drab',
  'restless',
  'goldfeverish',
  'bully',
  'insulting',
  'barbaric',
  'complaintive',
  'critical',
  'destructive',
  'facetious',
  'immature',
  'fickle',
  'cynical',
  'indulgent',
  'opportunistic',
  'plodding',
  'prim',
  'powerHungry',
  'overimaginative',
  'sanctimonious',
  'tense',
  'trendy',
  'zealous',
  'wroth',
  'timid',
  'selfish',
  'stiff',
  'spineless',
  'quarrelsome',
  'gullible',
  'belligerent',
  'obvious',
].map((trait) => entry(trait));
