import { moreNames } from 'rng/attributions';
import { TableEntry } from 'types/character';

const entry = (id: string): TableEntry => ({
  id: `more-names-${id}`,
  tags: ['mcglintlock', 'moreNames', 'name', id],
  attribution: moreNames,
  content: {
    tags: ['mcglintlock', 'moreNames', 'name', id],
    title: {
      id: 'character.stats.titles.name',
      values: {},
    },
    description: {
      id: 'character.stats.standard.name',
      values: { name: id },
    },
  },
});

export const names = [
  "Absorb",
  "Admete",
  "Agam",
  "Agen",
  "Agnaryd",
  "Agne",
  "Aina",
  "Aksdal",
  "Akurum",
  "Akut",
  "Alarm",
  "Alex",
  "Alg",
  "Alkalisk",
  "Allak",
  "Allamåla",
  "Alsarp",
  "Alseda",
  "Alster",
  "Alsvik",
  "Alve",
  "Alvine",
  "Aläng",
  "Amalia",
  "Amon",
  "Amorf",
  "Andrea",
  "Andrup",
  "Andy",
  "Aneboda",
  "Anemon",
  "Anita",
  "Anja",
  "Annamoa",
  "Annars",
  "Anno",
  "Annons",
  "Anordna",
  "Anrik",
  "Ansluta",
  "Anssi",
  "Antifoni",
  "Antik",
  "Antilop",
  "Antonius",
  "Apa",
  "Apelskär",
  "Arholma",
  "Arild",
  "Arrak",
  "Arv",
  "Arvinn",
  "Asker",
  "Aspekt",
  "Aspelund",
  "Aspudden",
  "Aspvik",
  "Aster",
  "Astrakan",
  "Atna",
  "Attest",
  "Attityd",
  "Aukra",
  "Bagge",
  "Bagis",
  "Balk",
  "Balser",
  "Band",
  "Baren",
  "Barnslig",
  "Barometer",
  "Basisk",
  "Bastant",
  "Batist",
  "Beata",
  "Beddinge",
  "Behandla",
  "Behövd",
  "Bekväm",
  "Bellinge",
  "Benjamin",
  "Benno",
  "Bergsbo",
  "Berit",
  "Bernhard",
  "Berta",
  "Bertby",
  "Bertil",
  "Beryll",
  "Berätta",
  "Beröm",
  "Beskåda",
  "Bestå",
  "Beta",
  "Bevara",
  "Bibbi",
  "Biby",
  "Bigarrå",
  "Bigum",
  "Bild",
  "Bildad",
  "Billsta",
  "Billy",
  "Bissa",
  "Bitig",
  "Bjursta",
  "Bjurön",
  "Bjärnum",
  "Björkefall",
  "Björken",
  "Björkudden",
  "Björnholmen",
  "Blad",
  "Bladet",
  "Bladhult",
  "Blanda",
  "Blanken",
  "Blaska",
  "Blecka",
  "Blimp",
  "Blixt",
  "Block",
  "Blom",
  "Blomma",
  "Blomster",
  "Blomört",
  "Blossande",
  "Blunda",
  "Blåsippa",
  "Boalt",
  "Boasjö",
  "Boholmen",
  "Boliden",
  "Bollö",
  "Bolmen",
  "Bomull",
  "Bondis",
  "Bonett",
  "Boren",
  "Bornholm",
  "Borrby",
  "Borris",
  "Bosse",
  "Boås",
  "Brallis",
  "Branäs",
  "Brarup",
  "Brasa",
  "Bravera",
  "Bravur",
  "Bredskär",
  "Broder",
  "Brodyr",
  "Brommö",
  "Brum",
  "Brunkrissla",
  "Bryne",
  "Bräda",
  "Bullar",
  "Bumerang",
  "Burken",
  "Burs",
  "Bursjön",
  "Bygel",
  "Bygga",
  "Byholma",
  "Bådalen",
  "Bågvik",
  "Båven",
  "Bärbar",
  "Bärby",
  "Bästis",
  "Böja",
  "Bölsnäs",
  "Börje",
  "Calypso",
  "Capita",
  "Cecilia",
  "Celeber",
  "Centrera",
  "Cesium",
  "Charm",
  "Charmör",
  "Cirkel",
  "Clips",
  "Cyril",
  "Dacke",
  "Dalfred",
  "Dalselv",
  "Damast",
  "Data",
  "Dave",
  "Decennium",
  "Degernes",
  "Deka",
  "Dekad",
  "Delikat",
  "Dessert",
  "Detolf",
  "Dignitet",
  "Dilling",
  "Dimma",
  "Dimpa",
  "Dinera",
  "Diod",
  "Dioder",
  "Direkt",
  "Diska",
  "Diskret",
  "Distans",
  "Dito",
  "Ditte",
  "Division",
  "Djur",
  "Docksta",
  "Dofta",
  "Dokument",
  "Dold",
  "Domsjö",
  "Dragan",
  "Dragon",
  "Dragör",
  "Dribbla",
  "Drill",
  "Droppar",
  "Drops",
  "Dryckjom",
  "Drälla",
  "Drömma",
  "Drömmar",
  "Dröna",
  "Duderö",
  "Duktig",
  "Dutt",
  "Dvala",
  "Dygn",
  "Dyning",
  "Dåtid",
  "Dämpa",
  "Dänka",
  "Edgar",
  "Edit",
  "Edland",
  "Edsvik",
  "Effektiv",
  "Egby",
  "Egeby",
  "Eide",
  "Eina",
  "Ekarp",
  "Ekby",
  "Ekorre",
  "Ektorp",
  "Ekås",
  "Eldig",
  "Elgå",
  "Elias",
  "Elisabet",
  "Elly",
  "Elsfjord",
  "Elverdam",
  "Emsen",
  "Emu",
  "Endiv",
  "Energisk",
  "Engan",
  "Engenes",
  "Enger",
  "Enje",
  "Ensemble",
  "Ensen",
  "Envis",
  "Epistel",
  "Epok",
  "Erbium",
  "Erfjord",
  "Erik",
  "Erikslund",
  "Erling",
  "Erserud",
  "Erslev",
  "Evabritt",
  "Evert",
  "Exklusivt",
  "Expedit",
  "Fabian",
  "Fabler",
  "Fado",
  "Famnig",
  "Fanaholm",
  "Fansta",
  "Fantast",
  "Fantastisk",
  "Fartyg",
  "Farum",
  "Fas",
  "Fastbo",
  "Fattbar",
  "Favorit",
  "Fejka",
  "Fejö",
  "Felicia",
  "Femmen",
  "Fenomen",
  "Fialena",
  "Fibbe",
  "Fidjetun",
  "Figgjo",
  "Figur",
  "Fil",
  "Fillsta",
  "Filt",
  "Finlir",
  "Finnvik",
  "Fintorp",
  "Fira",
  "Fisk",
  "Fiskö",
  "Fixa",
  "Fjordgard",
  "Fjädrar",
  "Fjällsta",
  "Fladdra",
  "Flaksa",
  "Flanör",
  "Flaten",
  "Flit",
  "Flokati",
  "Flokenes",
  "Flora",
  "Florera",
  "Florvåg",
  "Florö",
  "Flukta",
  "Flygel",
  "Flyn",
  "Flyt",
  "Flytta",
  "Flåren",
  "Fläckig",
  "Fläng",
  "Flärke",
  "Fläta",
  "Flört",
  "Fniss",
  "Folk",
  "Fonnes",
  "Form",
  "Format",
  "Forsytia",
  "Forså",
  "Fossing",
  "Foto",
  "Frakta",
  "Framstå",
  "Framtid",
  "Franklin",
  "Freden",
  "Fredrik",
  "Fredrika",
  "Frost",
  "Frosta",
  "Fryken",
  "Fräck",
  "Fräjen",
  "Frö",
  "Fuga",
  "Fusion",
  "Fyllen",
  "Fåborg",
  "Fångst",
  "Fåvang",
  "Färgglad",
  "Färgrik",
  "Färm",
  "Förhöja",
  "Förnuft",
  "Försiktig",
  "Förvar",
  "Gabbeh",
  "Galant",
  "Galej",
  "Gasell",
  "Gatten",
  "Gedser",
  "Gemak",
  "Gideå",
  "Gilbert",
  "Gilda",
  "Gimån",
  "Glana",
  "Glasholm",
  "Glenn",
  "Gles",
  "Glimma",
  "Glimt",
  "Glis",
  "Glitter",
  "Global",
  "Glänsa",
  "Glättig",
  "Glöda",
  "Gnarp",
  "Gnistra",
  "Godis",
  "Godmorgon",
  "Goliat",
  "Gorm",
  "Gosa",
  "Gosig",
  "Granat",
  "Granemo",
  "Granliden",
  "Granås",
  "Grapefrukt",
  "Gratinera",
  "Gravyr",
  "Gregor",
  "Grej",
  "Grevbäck",
  "Grilla",
  "Griller",
  "Grimen",
  "Grimle",
  "Grimstad",
  "Grip",
  "Groda",
  "Groggy",
  "Groland",
  "Grundtal",
  "Grunka",
  "Gryby",
  "Gränum",
  "Gräs",
  "Grönö",
  "Guldlin",
  "Gullholmen",
  "Gulliver",
  "Gullunge",
  "Gunilla",
  "Gunvor",
  "Gurkört",
  "Gustav",
  "Gyllen",
  "Gynnsam",
  "Gångbar",
  "Gåsgrund",
  "Gåva",
  "Gäspa",
  "Gömmaren",
  "Hagalund",
  "Hagavik",
  "Hake",
  "Hallaryd",
  "Halogen",
  "Halsted",
  "Hamnvik",
  "Hampen",
  "Hannes",
  "Harestua",
  "Harola",
  "Harry",
  "Hassel",
  "Hatten",
  "Havbro",
  "Havtorn",
  "Heat",
  "Hedda",
  "Hedra",
  "Heidal",
  "Heimdal",
  "Hejka",
  "Hellum",
  "Helmer",
  "Helsingör",
  "Hemlis",
  "Hemma",
  "Hemmet",
  "Hemnes",
  "Hemsjö",
  "Henriksdal",
  "Hensvik",
  "Herand",
  "Herman",
  "Hermelin",
  "Hessum",
  "Hjartdal",
  "Hjelmås",
  "Hjuvik",
  "Hjärta",
  "Hjördis",
  "Hob",
  "Hojta",
  "Hoo",
  "Hopa",
  "Hopen",
  "Hosle",
  "Hoven",
  "Hovet",
  "Hovskär",
  "Hovås",
  "Huglo",
  "Hugo",
  "Hulda",
  "Hulingen",
  "Hultet",
  "Hultö",
  "Husnes",
  "Husvik",
  "Huså",
  "Husön",
  "Hutten",
  "Hyllis",
  "Hyttan",
  "Håbol",
  "Hållare",
  "Håva",
  "Hägg",
  "Hälsa",
  "Hästveda",
  "Hästö",
  "Höllviken",
  "Hörby",
  "Ibestad",
  "Ideal",
  "Idealisk",
  "Idelig",
  "Idgrund",
  "Idyll",
  "Ikea",
  "Ilsbo",
  "Impuls",
  "Index",
  "Indira",
  "Indo",
  "Inez",
  "Info",
  "Ingemar",
  "Inger",
  "Ingo",
  "Ingolf",
  "Inlopp",
  "Inner",
  "Inreda",
  "Insats",
  "Iris",
  "Irja",
  "Irma",
  "Isdans",
  "Iskorn",
  "Istad",
  "Ivar",
  "Ivetofta",
  "Jabba",
  "Jakob",
  "Janne",
  "Jansjö",
  "Januari",
  "Jaren",
  "Javnaker",
  "Jeff",
  "Jerrik",
  "Jesper",
  "Jigga",
  "Joakim",
  "Johan",
  "Joker",
  "Jokkmokk",
  "Jonas",
  "Jondal",
  "Jonisk",
  "Jonsberg",
  "Jonsbo",
  "Jorun",
  "Jubilar",
  "Jubla",
  "Jules",
  "Julius",
  "Julros",
  "Jyllinge",
  "Jägra",
  "Jämsunda",
  "Järlebo",
  "Jökel",
  "Kaffe",
  "Kaj",
  "Kajsa",
  "Kajsastina",
  "Kalas",
  "Kalaset",
  "Kallt",
  "Kamfer",
  "Kamrater",
  "Kanin",
  "Kanist",
  "Kapa",
  "Karby",
  "Kardemumma",
  "Karens",
  "Karlskrona",
  "Karlstad",
  "Karlsö",
  "Karsten",
  "Kassett",
  "Kastanj",
  "Kattrup",
  "Kattudden",
  "Kaustby",
  "Kavalkad",
  "Kaxig",
  "Kelig",
  "Ketty",
  "Kibäk",
  "Kilan",
  "Kilby",
  "Kirp",
  "Kivsta",
  "Klack",
  "Kladd",
  "Klappsta",
  "Klaviatur",
  "Klavsta",
  "Klemens",
  "Klingsbo",
  "Klippan",
  "Klippig",
  "Klobo",
  "Kloss",
  "Klubbo",
  "Kludd",
  "Kläppe",
  "Klätt",
  "Knappa",
  "Knaster",
  "Knipsa",
  "Knodd",
  "Knoppa",
  "Knubbig",
  "Knuff",
  "Knyck",
  "Knös",
  "Koja",
  "Koldby",
  "Kolja",
  "Kolon",
  "Komisk",
  "Kommers",
  "Kompisar",
  "Komplement",
  "Koncis",
  "Konst",
  "Koppang",
  "Korall",
  "Korpo",
  "Korrekt",
  "Korsfjord",
  "Kort",
  "Kosing",
  "Kottar",
  "Kotte",
  "Kottebo",
  "Krabb",
  "Kraftig",
  "Kram",
  "Krama",
  "Kramfors",
  "Kramsnö",
  "Kristaller",
  "Kritter",
  "Kroby",
  "Kroken",
  "Krokvik",
  "Krus",
  "Kryp",
  "Kryssbo",
  "Krämare",
  "Kulla",
  "Kullen",
  "Kura",
  "Kuvert",
  "Kvarnsten",
  "Kvart",
  "Kvarta",
  "Kvartal",
  "Kviby",
  "Kvintett",
  "Kvintol",
  "Kvissel",
  "Kvist",
  "Kyligt",
  "Kåraboda",
  "Kärna",
  "Köge",
  "Lack",
  "Ladda",
  "Lade",
  "Lagan",
  "Lagra",
  "Laiva",
  "Lampan",
  "Lanni",
  "Lansa",
  "Laukvik",
  "Laver",
  "Laxeby",
  "Legitim",
  "Legym",
  "Leirvik",
  "Leka",
  "Lekman",
  "Lekplats",
  "Leksvik",
  "Len",
  "Lena",
  "Lenda",
  "Leran",
  "Lerberg",
  "Lerbäck",
  "Lerdal",
  "Lersta",
  "Levanger",
  "Liared",
  "Liatorp",
  "Lidan",
  "Lilja",
  "Lill",
  "Lillabo",
  "Lillberg",
  "Lilleby",
  "Lillesand",
  "Lillholmen",
  "Lillåker",
  "Lillången",
  "Limmaren",
  "Lindmon",
  "Lindsdal",
  "Lindved",
  "Lingo",
  "Linje",
  "Linnarp",
  "Ljuda",
  "Ljung",
  "Ljusdal",
  "Ljusnan",
  "Ljusås",
  "Ljuv",
  "Ljuvlig",
  "Lock",
  "Logga",
  "Lokka",
  "Lonevåg",
  "Lossnen",
  "Lots",
  "Lovande",
  "Loviken",
  "Ludde",
  "Ludvig",
  "Luftig",
  "Lugn",
  "Lummig",
  "Luns",
  "Lunta",
  "Lupin",
  "Luröy",
  "Lustifik",
  "Lusy",
  "Lycka",
  "Lyckhem",
  "Lycksele",
  "Lydum",
  "Lyft",
  "Lykta",
  "Lyster",
  "Lågis",
  "Läkt",
  "Lämplig",
  "Lätt",
  "Lättsam",
  "Lökö",
  "Lömsk",
  "Löv",
  "Löva",
  "Mackis",
  "Magasin",
  "Maggan",
  "Magnifik",
  "Majby",
  "Majken",
  "Majvor",
  "Malm",
  "Malma",
  "Malou",
  "Malte",
  "Mammut",
  "Mandal",
  "Mandel",
  "Manger",
  "Manne",
  "Maren",
  "Marginal",
  "Marieberg",
  "Marius",
  "Markland",
  "Markus",
  "Markör",
  "Martin",
  "Mata",
  "Matilda",
  "Matteus",
  "Mavas",
  "Medalj",
  "Medaljong",
  "Melbu",
  "Meldal",
  "Melltorp",
  "Melodi",
  "Merete",
  "Metall",
  "Metrik",
  "Mien",
  "Mikael",
  "Mil",
  "Minde",
  "Minifångst",
  "Minna",
  "Minnen",
  "Mix",
  "Mixa",
  "Mixtur",
  "Mjällby",
  "Mjöd",
  "Mjönäs",
  "Molger",
  "Molnig",
  "Mongstad",
  "Moses",
  "Mosippa",
  "Mosjö",
  "Motorp",
  "Motto",
  "Muddus",
  "Mula",
  "Mumsig",
  "Musik",
  "Mydal",
  "Myken",
  "Mys",
  "Mysa",
  "Myskmalva",
  "Måbär",
  "Måla",
  "Månstad",
  "Måsnan",
  "Måttlig",
  "Mälarö",
  "Märit",
  "Mökern",
  "Mörker",
  "Mörrum",
  "Nandor",
  "Natt",
  "Neglinge",
  "Nejlika",
  "Nektarin",
  "Nidelva",
  "Nils",
  "Nisse",
  "Nivå",
  "Njuta",
  "Noga",
  "Nojs",
  "Nominell",
  "Non",
  "Norbo",
  "Nordanö",
  "Norden",
  "Nordmyra",
  "Noresund",
  "Norna",
  "Norrebo",
  "Norreskog",
  "Norrsken",
  "Norrsten",
  "Norvald",
  "Nostalgisk",
  "Not",
  "Numerär",
  "Nutid",
  "Nysted",
  "Nytta",
  "Nyttig",
  "Nyttja",
  "Näckten",
  "Nära",
  "Näsby",
  "Nässjö",
  "Nästved",
  "Näsum",
  "Nätt",
  "Nöje",
  "Obi",
  "Observatör",
  "Odda",
  "Oddvar",
  "Ofelia",
  "Olarp",
  "Olle",
  "Olunda",
  "Omar",
  "Omsorg",
  "Optimal",
  "Ordning",
  "Ore",
  "Orgel",
  "Orkide",
  "Ormo",
  "Orrlöt",
  "Oröd",
  "Oxel",
  "Oxskär",
  "Papaja",
  "Paragraf",
  "Park",
  "Patricia",
  "Patrik",
  "Patrull",
  "Pax",
  "Pello",
  "Persika",
  "Persisk",
  "Petronella",
  "Pilatorp",
  "Pinje",
  "Piro",
  "Pjäs",
  "Pjätteryd",
  "Plan",
  "Plank",
  "Plastis",
  "Platta",
  "Plinga",
  "Plus",
  "Plutt",
  "Pokal",
  "Polarvide",
  "Pomp",
  "Portis",
  "Povel",
  "Poäng",
  "Praktfull",
  "Premiär",
  "Pressa",
  "Prestera",
  "Prickig",
  "Produkt",
  "Prompt",
  "Proper",
  "Pruta",
  "Prägel",
  "Präktig",
  "Pröjs",
  "Ps",
  "Pugg",
  "Pult",
  "Punkt",
  "Pyra",
  "Pyssla",
  "Pålitlig",
  "Påtår",
  "Pällbo",
  "Rabalder",
  "Radium",
  "Ragna",
  "Rajtan",
  "Raket",
  "Ram",
  "Ramvik",
  "Rand",
  "Raps",
  "Rara",
  "Raritet",
  "Rast",
  "Rationell",
  "Rebbenes",
  "Reda",
  "Redalen",
  "Redd",
  "Regolit",
  "Reko",
  "Rektangel",
  "Renlig",
  "Rens",
  "Resa",
  "Reslig",
  "Resurs",
  "Reträtt",
  "Rexbo",
  "Ribba",
  "Rickard",
  "Rigel",
  "Rigga",
  "Riktig",
  "Rill",
  "Ringsjön",
  "Ringskär",
  "Ringum",
  "Ripe",
  "Risten",
  "Ritva",
  "Rival",
  "Rofylld",
  "Roger",
  "Rosor",
  "Rotera",
  "Rudolf",
  "Rugga",
  "Rull",
  "Rund",
  "Rundel",
  "Rusch",
  "Rusig",
  "Rustik",
  "Ruta",
  "Rutbo",
  "Ruter",
  "Rutger",
  "Råån",
  "Räffla",
  "Ränder",
  "Ränta",
  "Rättvik",
  "Rönn",
  "Rönnbär",
  "Rönnskär",
  "Rönås",
  "Röra",
  "Sagolik",
  "Salbo",
  "Salmi",
  "Salong",
  "Saltskär",
  "Samla",
  "Samtid",
  "Sanela",
  "Sanni",
  "Saralisa",
  "Sarita",
  "Satin",
  "Saxån",
  "Sebastian",
  "Sekin",
  "Semvik",
  "Senior",
  "Signe",
  "Signera",
  "Signum",
  "Sigrid",
  "Siljan",
  "Sindal",
  "Sirlig",
  "Sitta",
  "Sjövik",
  "Skaffa",
  "Skala",
  "Skimmer",
  "Skimra",
  "Skir",
  "Skoj",
  "Skojig",
  "Skrapa",
  "Skribent",
  "Skrin",
  "Skruvsta",
  "Skubb",
  "Skuggig",
  "Skutta",
  "Skydd",
  "Skydda",
  "Skålig",
  "Skänka",
  "Skär",
  "Sköldpadda",
  "Slabang",
  "Slinga",
  "Slipad",
  "Slitbar",
  "Slom",
  "Slugger",
  "Slugis",
  "Slumra",
  "Slån",
  "Slätten",
  "Slätthult",
  "Smaka",
  "Smarta",
  "Smaska",
  "Smedsta",
  "Smidd",
  "Smila",
  "Smycka",
  "Smådal",
  "Småland",
  "Smärt",
  "Snack",
  "Snarka",
  "Sniglar",
  "Snille",
  "Snitsig",
  "Snitta",
  "Snodd",
  "Snudda",
  "Snurr",
  "Snålis",
  "Snår",
  "Snäll",
  "Snärtig",
  "Snöa",
  "Snöflinga",
  "Snöstjärna",
  "Sockerärt",
  "Soda",
  "Sofia",
  "Sol",
  "Solig",
  "Solsta",
  "Solvar",
  "Solveig",
  "Somrig",
  "Sorla",
  "Sortera",
  "Sorti",
  "Sova",
  "Spalt",
  "Sparka",
  "Sparsam",
  "Speja",
  "Spoling",
  "Spontan",
  "Sprallig",
  "Spänn",
  "Spärra",
  "Spöka",
  "Stabil",
  "Stave",
  "Stefan",
  "Stek",
  "Steka",
  "Sten",
  "Stenstorp",
  "Stiby",
  "Sticka",
  "Stil",
  "Stjälk",
  "Stjärna",
  "Stockholm",
  "Stolmen",
  "Stopp",
  "Storm",
  "Stornäs",
  "Storå",
  "Stratosfär",
  "Strecket",
  "Strib",
  "Strikt",
  "Strila",
  "Strind",
  "Strå",
  "Strålande",
  "Stuga",
  "Stycke",
  "Ställ",
  "Sultan",
  "Summera",
  "Sune",
  "Sunnan",
  "Superfin",
  "Sussa",
  "Suverän",
  "Svajs",
  "Svala",
  "Svalka",
  "Sveje",
  "Svind",
  "Svinga",
  "Svit",
  "Sy",
  "Sylling",
  "Syntes",
  "Syren",
  "Säter",
  "Sävern",
  "Sörli",
  "Sörum",
  "Tacksam",
  "Tafjord",
  "Tag",
  "Tajma",
  "Tajt",
  "Tallvik",
  "Tanja",
  "Tassa",
  "Tecken",
  "Tekla",
  "Teppas",
  "Terje",
  "Termosfär",
  "Tertial",
  "Textur",
  "Thisted",
  "Tidlösa",
  "Tillaga",
  "Tindra",
  "Tistel",
  "Titta",
  "Tjusig",
  "Tjöme",
  "Tobias",
  "Tobo",
  "Toftbo",
  "Toga",
  "Tokig",
  "Tolg",
  "Tolga",
  "Torbjörn",
  "Torka",
  "Torsby",
  "Tostarp",
  "Traktor",
  "Trampa",
  "Tranby",
  "Trandal",
  "Tratt",
  "Trensum",
  "Trilling",
  "Tripp",
  "Trivsam",
  "Trivsel",
  "Trofast",
  "Trojka",
  "Trolig",
  "Trollfjorden",
  "Trollsta",
  "Trolsk",
  "Tromsnes",
  "Tromsö",
  "Trondheim",
  "Trones",
  "Tross",
  "Truls",
  "Trygg",
  "Tryta",
  "Träd",
  "Tullerö",
  "Tullsta",
  "Tulpan",
  "Tundra",
  "Tupplur",
  "Tveit",
  "Tvilling",
  "Tvätta",
  "Tyda",
  "Tyglösa",
  "Tylösand",
  "Tårnby",
  "Tårsta",
  "Täljare",
  "Tärnan",
  "Tärnö",
  "Uldum",
  "Ulsberg",
  "Ung",
  "Unni",
  "Uppenbar",
  "Uppleva",
  "Upptäcka",
  "Urban",
  "Usta",
  "Utby",
  "Utmärkt",
  "Uvås",
  "Vacker",
  "Vaken",
  "Valby",
  "Valsund",
  "Vanvik",
  "Variera",
  "Varsam",
  "Vasen",
  "Vass",
  "Vejmon",
  "Verklig",
  "Verksam",
  "Versal",
  "Vessla",
  "Vete",
  "Vide",
  "Vika",
  "Vikare",
  "Vikis",
  "Vila",
  "Vildbär",
  "Vilse",
  "Vilshult",
  "Vinde",
  "Vinjett",
  "Vink",
  "Vinna",
  "Vinstra",
  "Vippa",
  "Viren",
  "Virre",
  "Visen",
  "Vistofta",
  "Vitamin",
  "Vitaminer",
  "Vitskär",
  "Vitten",
  "Vivan",
  "Volym",
  "Vreta",
  "Vreten",
  "Vurm",
  "Våg",
  "Vågen",
  "Vållö",
  "Vårljung",
  "Vädd",
  "Väggis",
  "Väghult",
  "Vänlig",
  "Vänna",
  "Värde",
  "Värld",
  "Värme",
  "Väv",
  "Växt",
  "Wilma",
  "Yngsjö",
  "Ysby",
  "Ytterlig",
  "Zita",
  "Ådal",
  "Ånes",
  "Ånn",
  "Årlig",
  "Årstid",
  "Åsarp",
  "Åsele",
  "Åsunden",
  "Älmhult",
  "Änga",
  "Äpplarö",
  "Ärbar",
  "Ätran",
  "Ödmjuk",
  "Ögla",
  "Önska",
  "Öppen",
  "Öresund",
  "Örter",
  "Österbymo",
  "Österö",
].map((name) => entry(name));
